import { serviceCommon } from './common';
import { apiConstants } from './constants';

const login = async (email, password, abortController) => {
	let restObj = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			email: email,
			password: password,
		}),
	};
	if (abortController) {
		restObj.signal = abortController.signal;
	}
	let url = apiConstants.ADMIN_LOGIN;

	try {
		let response = await fetch(url, restObj);
		let body = await response.json();
		return body;
	} catch (e) {
		return null;
	}
};

const getList = async (requestBody, contentTypeUID, abortController) => {
	let restObj = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: serviceCommon.getToken(),
		},
	};
	if (abortController) {
		restObj.signal = abortController.signal;
	}
	let query = '';
	if (requestBody) {
		query =
			(requestBody.pageNo ? `page=${requestBody.pageNo}&` : '') +
			(requestBody.pageSize ? `pageSize=${requestBody.pageSize}&` : '') +
			(requestBody.sort ? `_sort=${requestBody.sort}&` : '') +
			(requestBody.search ? `_q=${requestBody.search}` : '');
	}
	let url = apiConstants.ADMIN_GET_LIST(query, contentTypeUID);

	try {
		let response = await fetch(url, restObj);
		let body = await response.json();
		return body;
	} catch (e) {
		return null;
	}
};

const getDetail = async (id, contentTypeUID, abortController) => {
	let restObj = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: serviceCommon.getToken(),
		},
	};
	if (abortController) {
		restObj.signal = abortController.signal;
	}

	let url = apiConstants.ADMIN_GET(id, contentTypeUID);

	try {
		let response = await fetch(url, restObj);
		let body = await response.json();
		return body;
	} catch (e) {
		return null;
	}
};

const createItem = async (requestBody, contentTypeUID, abortController) => {
	let restObj = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: serviceCommon.getToken(),
		},
		body: JSON.stringify(requestBody),
	};
	if (abortController) {
		restObj.signal = abortController.signal;
	}

	let url = apiConstants.ADMIN_CREATE(contentTypeUID);

	try {
		let response = await fetch(url, restObj);
		let body = await response.json();
		return body;
	} catch (e) {
		return null;
	}
};
const updateDetail = async (id, requestBody, contentTypeUID, abortController) => {
	let restObj = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			Authorization: serviceCommon.getToken(),
		},
		body: JSON.stringify(requestBody),
	};
	if (abortController) {
		restObj.signal = abortController.signal;
	}

	let url = apiConstants.ADMIN_GET(id, contentTypeUID);

	try {
		let response = await fetch(url, restObj);
		let body = await response.json();
		return body;
	} catch (e) {
		return null;
	}
};

const deleteItem = async (id, contentTypeUID, abortController) => {
	let restObj = {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
			Authorization: serviceCommon.getToken(),
		},
	};
	if (abortController) {
		restObj.signal = abortController.signal;
	}

	let url = apiConstants.ADMIN_GET(id, contentTypeUID);

	try {
		let response = await fetch(url, restObj);
		let body = await response.json();
		return body;
	} catch (e) {
		return null;
	}
};

const generateUid = async (requestBody, abortController) => {
	let restObj = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: serviceCommon.getToken(),
		},
		body: JSON.stringify(requestBody),
	};
	if (abortController) {
		restObj.signal = abortController.signal;
	}

	let url = apiConstants.ADMIN_GENERATE_UID;

	try {
		let response = await fetch(url, restObj);
		let body = await response.json();
		return body;
	} catch (e) {
		return null;
	}
};

const publish = async (id, contentTypeUID, abortController) => {
	let restObj = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: serviceCommon.getToken(),
		},
	};
	if (abortController) {
		restObj.signal = abortController.signal;
	}

	let url = apiConstants.PUBLISH(id, contentTypeUID);

	try {
		let response = await fetch(url, restObj);
		let body = await response.json();
		return body;
	} catch (e) {
		return null;
	}
};

const upload = async (file, abortController) => {
	let formData = new FormData();
	formData.append('files', file);
	formData.append(
		'fileInfo',
		JSON.stringify({
			alternativeText: '',
			caption: '',
			name: null,
		})
	);
	let restObj = {
		method: 'POST',
		headers: {
			Authorization: serviceCommon.getToken(),
		},
		body: formData,
	};
	if (abortController) {
		restObj.signal = abortController.signal;
	}

	let url = apiConstants.UPLOAD;

	try {
		let response = await fetch(url, restObj);
		let body = await response.json();
		return body;
	} catch (e) {
		return null;
	}
};

const getSinglePage = async (contentTypeUID, abortController) => {
	let restObj = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: serviceCommon.getToken(),
		},
	};
	if (abortController) {
		restObj.signal = abortController.signal;
	}

	let url = apiConstants.GET_SINGLE_TYPE(contentTypeUID);

	try {
		let response = await fetch(url, restObj);
		let body = await response.json();
		return body;
	} catch (e) {
		return null;
	}
};
const updateSinglePage = async (contentTypeUID, body, abortController) => {
	let restObj = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			Authorization: serviceCommon.getToken(),
		},
		body: JSON.stringify(body),
	};
	if (abortController) {
		restObj.signal = abortController.signal;
	}

	let url = apiConstants.GET_SINGLE_TYPE(contentTypeUID);

	try {
		let response = await fetch(url, restObj);
		let body = await response.json();
		return body;
	} catch (e) {
		return null;
	}
};

const getListUser = async (requestBody, abortController) => {
	let restObj = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: serviceCommon.getToken(),
		},
	};
	if (abortController) {
		restObj.signal = abortController.signal;
	}
	let query = '';
	if (requestBody) {
		query =
			(requestBody.pageSize ? `pageSize=${requestBody.pageSize}&` : '') +
			(requestBody.pageNo ? `page=${requestBody.pageNo}&` : '') +
			(requestBody.sort ? `_sort=${requestBody.sort}&` : '') +
			(requestBody.search ? `_q=${requestBody.search}` : '');
	}
	let url = apiConstants.ADMIN_GET_LIST_USER(query);

	try {
		let response = await fetch(url, restObj);
		let body = await response.json();
		if (body.data) {
			return body.data;
		}
		return null;
	} catch (e) {
		return null;
	}
};

const createUser = async (requestBody, abortController) => {
	let restObj = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: serviceCommon.getToken(),
		},
		body: JSON.stringify(requestBody),
	};
	if (abortController) {
		restObj.signal = abortController.signal;
	}

	let url = apiConstants.ADMIN_CREATE_USER;

	try {
		let response = await fetch(url, restObj);
		let body = await response.json();
		if (body.data) {
			return body.data;
		} else if (body.statusCode === 400) {
			return body.statusCode;
		}
		return null;
	} catch (e) {
		return null;
	}
};

const updateUser = async (id, requestBody, abortController) => {
	let restObj = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			Authorization: serviceCommon.getToken(),
		},
		body: JSON.stringify(requestBody),
	};
	if (abortController) {
		restObj.signal = abortController.signal;
	}

	let url = apiConstants.ADMIN_UPDATE_USER(id);

	try {
		let response = await fetch(url, restObj);
		let body = await response.json();
		return body;
	} catch (e) {
		return null;
	}
};

const deleteUser = async (ids, abortController) => {
	let restObj = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: serviceCommon.getToken(),
		},
		body: JSON.stringify({ ids: ids }),
	};
	if (abortController) {
		restObj.signal = abortController.signal;
	}

	let url = apiConstants.ADMIN_DELETE_USER;

	try {
		let response = await fetch(url, restObj);
		let body = await response.json();
		if (body.data) {
			return body.data;
		}
		return null;
	} catch (e) {
		return null;
	}
};

const getRegistrationInfo = async (registrationToken, abortController) => {
	let restObj = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	};
	if (abortController) {
		restObj.signal = abortController.signal;
	}

	let url = apiConstants.ADMIN_GET_REGISTRATION_INFO(registrationToken);

	try {
		let response = await fetch(url, restObj);
		let body = await response.json();
		if (body) {
			return body;
		}
		return null;
	} catch (e) {
		return null;
	}
};

const register = async (body, abortController) => {
	let restObj = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	};
	if (abortController) {
		restObj.signal = abortController.signal;
	}

	let url = apiConstants.ADMIN_REGISTER;

	try {
		let response = await fetch(url, restObj);
		let body = await response.json();
		return body;
	} catch (e) {
		return null;
	}
};

const getCategoriesOfProject = async (id, abortController) => {
	let restObj = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: serviceCommon.getToken(),
		},
	};
	if (abortController) {
		restObj.signal = abortController.signal;
	}

	let url = apiConstants.ADMIN_GET_CATEGORIES_OF_PROJECT(id);

	try {
		let response = await fetch(url, restObj);
		let body = await response.json();
		return body.categories;
	} catch (e) {
		return null;
	}
};

export const adminApi = {
	login,
	generateUid,
	getList,
	getDetail,
	createItem,
	updateDetail,
	deleteItem,
	publish,
	upload,
	getSinglePage,
	updateSinglePage,
	getListUser,
	createUser,
	updateUser,
	deleteUser,
	getRegistrationInfo,
	register,
	getCategoriesOfProject,
};
